import { CheckCircleIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";
import { COLOR_PALETTE } from "../../shared/colors";
import { useNavigate, useSearchParams } from "react-router-dom";
import NavBar from "../NavBar/NavBar";

export const SuccessPackage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Use searchParams to extract package_type
  const packageType = searchParams.get("package_type");

  // Format the package type to a readable string
  const formatPackageType = (packageTypeString) => {
    if (packageTypeString === 'package_3_reservations') {
      return '3 reservations';
    } else if (packageTypeString === 'package_5_reservations') {
      return '5 reservations';
    }
    return packageTypeString;
  };

  return (
    <Flex backgroundColor={COLOR_PALETTE.background} height="100vh">
      <NavBar navigateTo="/choose_date" />
      <Flex 
        direction="column" 
        alignItems="center" 
        width="100%" 
        marginTop="60%"
      >
        <CheckCircleIcon color="#2E8B57" boxSize={20} />
        <Text 
          mt="20px" 
          color="white" 
          as="h3" 
          textAlign="center"
          px={4}
        >
          {`Your package purchase was successful!`}
        </Text>
        <Text 
          mt="10px" 
          color="white" 
          fontSize="m" 
          textAlign="center"
          px={4}
        >
          Check your email for confirmation
        </Text>
      </Flex>
    </Flex>
  );
};