// src/components/SpecialOfferModal/SpecialOfferModal.jsx
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const SpecialOfferModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const handleViewOffers = () => {
    navigate('/credit-packages');
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={4}>
        <ModalHeader>Special Offer!</ModalHeader>
        <ModalBody>
          <Text fontSize="lg">
            Pay in advance and get a discount on your reservations!
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" mr={3} onClick={handleViewOffers}>
            View Offers
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};