import React from 'react';
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  VStack,
  Text,
  Button,
  useToast,
  Flex,
} from '@chakra-ui/react';
import { COLOR_PALETTE } from '../../shared/colors';
import { useNavigate } from 'react-router-dom';
import axios from '../../apiUtils';

export const CreditPackages = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const packages = [
    { credits: 3, price: 4500, type: 'package-3'},
    { credits: 5, price: 6000, type: 'package-5'},
  ];

  const handlePurchase = async (pkg) => {
    try {
      // First try to get the current user data from the API
      let user;
      try {
        const userResponse = await axios.get('/me/');
        user = userResponse.data;
      } catch (userError) {
        // If API call fails, try localStorage as fallback
        user = JSON.parse(localStorage.getItem('user'));
      }
      
      if (!user || !user.id) {
        toast({
          title: 'Error',
          description: 'User information not found. Please log in again.',
          status: 'error',
          duration: 3000,
        });
        return;
      }
  
      const confirmed = window.confirm(`Purchase ${pkg.credits} reservations package for ${pkg.price} MKD?`);
      if (!confirmed) return;
  
      const response = await axios.post("/reservations/reserve-package/", {
        user_id: user.id,
        package_type: pkg.type
      });
  
      if (response.data.redirectUrl) {
        window.location.href = response.data.redirectUrl;
      }
    } catch (error) {
      console.error("Package purchase error:", error);
      toast({
        title: 'Error',
        description: error.response?.data?.error || "Failed to purchase package",
        status: 'error',
        duration: 3000,
      });
    }
  };

  return (
    <Box minH="100vh" bg={COLOR_PALETTE.background} py={10}>
      <Container maxW="container.lg">
        <VStack spacing={8}>
          <Heading color="white" mb={6}>
            Reservation Packages
          </Heading>
          <Text 
            color="white" 
            mb={3} 
            fontSize="xl" 
            textAlign="center"
          >
            Pay in advance and get a discount on your reservations!
            After you purchase a package, just check your email for confirmation. The credits are valid until the 1st of June.
          </Text>
          <Flex justify="center" w="full">
            <SimpleGrid 
              columns={{ base: 1, md: 2 }} 
              spacing={10} 
              maxW="1200px"
            >
              {packages.map((pkg) => (
                <Box
                  key={pkg.credits}
                  bg="white"
                  p={6}
                  borderRadius="lg"
                  boxShadow="xl"
                  textAlign="center"
                >
                  <VStack spacing={4}>
                    <Text fontSize="2xl" fontWeight="bold">
                      {pkg.credits} Reservations
                    </Text>
                    <Text fontSize="xl">{pkg.price} MKD</Text>
                    <Button
                      colorScheme="teal"
                      size="lg"
                      w="full"
                      onClick={() => handlePurchase(pkg)}
                    >
                      Select Package
                    </Button>
                  </VStack>
                </Box>
              ))}
            </SimpleGrid>
          </Flex>
          <Button
            bg="white"
            color={COLOR_PALETTE.background}
            _hover={{ bg: 'gray.100' }}
            onClick={() => navigate('/choose_date')}
          >
            Back to Calendar
          </Button>
        </VStack>
      </Container>
    </Box>
  );
};