// src/pages/MembershipPackages/MembershipPackages.jsx
import React from 'react';
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  VStack,
  Text,
  Button,
  useToast,
} from '@chakra-ui/react';
import { COLOR_PALETTE } from '../../shared/colors';
import { useNavigate } from 'react-router-dom';

export const MembershipPackages = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const packages = [
    { months: 3, price: 9000, label: '3 Month Membership' },
    { months: 6, price: 16000, label: '6 Month Membership' },
    { months: 12, price: 28000, label: 'Annual Membership' },
  ];

  const handlePurchase = (pkg) => {
    // Add purchase logic here
    toast({
      title: 'Package Selected',
      description: `You selected ${pkg.months} months membership for ${pkg.price} MKD`,
      status: 'success',
      duration: 3000,
    });
    // Navigate to payment page or handle purchase
  };

  return (
    <Box minH="100vh" bg={COLOR_PALETTE.background} py={10}>
      <Container maxW="container.lg">
        <VStack spacing={8}>
          <Heading color="white" mb={6}>
            Membership Packages
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} w="full">
            {packages.map((pkg) => (
              <Box
                key={pkg.months}
                bg="white"
                p={6}
                borderRadius="lg"
                boxShadow="xl"
                textAlign="center"
              >
                <VStack spacing={4}>
                  <Heading size="md">{pkg.label}</Heading>
                  <Text fontSize="2xl" fontWeight="bold">
                    {pkg.months} Months
                  </Text>
                  <Text fontSize="xl">{pkg.price} MKD</Text>
                  <Button
                    colorScheme="teal"
                    size="lg"
                    w="full"
                    onClick={() => handlePurchase(pkg)}
                  >
                    Select Package
                  </Button>
                </VStack>
              </Box>
            ))}
          </SimpleGrid>
          <Button
            bg="white"
            color={COLOR_PALETTE.background}
            _hover={{ bg: 'gray.100' }}
            onClick={() => navigate('/student-dashboard')}
          >
            Back to Dashboard
          </Button>
        </VStack>
      </Container>
    </Box>
  );
};